import React from "react"
import "resources/style/style.less"
import Seo from "../components/seo"
import Layout from "components/layout"
import BosDataDelete from "components/section/bos-data-delete"

const IndexPage = () => {
  return (
    <>
      <Seo title={"Privacy Policy delete data Bos"} />
      <Layout headerVariant="blue" headerBackground="blue">
        <BosDataDelete />
      </Layout>
    </>
  )
}

export default IndexPage
