import React from "react"
import { Col, Row, Typography } from "antd"

import WispayLogoFinal from "resources/img/wispaylogo.svg"
import Fb from "resources/img/footer/facebook.png"
import Ig from "resources/img/footer/Instagram.png"

const Footer = () => {
  const date = new Date()

  return (
    <>
      <div className="container-footer">
        <Col spa xl={20} lg={20} md={0} sm={0} xs={0} className="Col-Footer">
          <Row>
            <Col span={5}>
              <img
                src={WispayLogoFinal}
                alt="logo wispay"
                className="wispay-footer-logo"
              />
            </Col>
            <Col span={15}>
              <a href="mailto:support@wis-pay.com">
                <Typography.Text className="text-normal-16 mtop-10">
                  Customer Support
                </Typography.Text>
              </a>{" "}
              <a href="/bos-privacy">
                <Typography.Text className="text-normal-16 mtop-15">
                  Privacy Policy
                </Typography.Text>
              </a>
            </Col>
            <Col span={4} className="medsos">
              <Row className="medsos-logo" style={{ display: "contents" }}>
                <Col span={8} style={{ textAlign: "right" }}>
                  <img src={Ig} alt="instagram logo" />
                </Col>
                <Col span={8} style={{ textAlign: "right" }}>
                  <img src={Fb} alt="facebook logo" />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </div>
      <div className="container-footer">
        <div className="divider-thin-grey mtop-40"></div>
        <Col span={4} className="medsos-responsive">
          <div className="medsos-logo">
            <Col span={20}>
              <Row>
                <Col span={8}>
                  <img src={Ig} alt="instagram logo" />
                </Col>

                <Col span={8}>
                  <img src={Fb} alt="facebook logo" />
                </Col>
              </Row>
            </Col>
          </div>
        </Col>
        <Col span={20} className="copyright">
          <Row align="middle">
            <Typography.Text className="text-normal-16 mtop-15 pb-30">
              Copyright ⓒ {date.getFullYear()} All rights reserved
            </Typography.Text>
          </Row>
        </Col>
      </div>
    </>
  )
}

export default Footer
