import React from "react"
import { Row, Col, Typography } from "antd"
const { Title, Paragraph } = Typography

const BosDataDelete = () => {
  return (
    <Row justify="center" style={{ margin: "70px 0" }}>
      <Col span={20}>
        <Title level={3} className="text-center">
          Kebijakan Penghapusan Data dan Penghapusan Akun
        </Title>
      </Col>
      <Col span={18}>
        <Title level={4} className="mtop-40">
          1. Ikhtisar
        </Title>
        <Paragraph>
          Di BOS, kami berkomitmen untuk melindungi privasi Anda dan memberikan
          Anda kontrol atas data pribadi Anda. Kebijakan Penghapusan Data dan
          Penghapusan Akun ini menguraikan prosedur dan pedoman untuk
          penghapusan data dan akun Anda dari platform kami.
        </Paragraph>
        <Title level={4} className="mtop-40">
          2. Penghapusan Data
        </Title>
        <Paragraph>
          <div className="bosparagraph">
            <div>2.1.</div>{" "}
            <div>
              <p>
                Data Pengguna: Atas permintaan, kami akan segera menghapus semua
                data pribadi yang terkait dengan akun Anda dari sistem kami. Ini
                termasuk namun tidak terbatas pada:
              </p>
              <p>
                Informasi profil pengguna Riwayat penggunaan Konten yang
                diunggah
              </p>
            </div>
          </div>
        </Paragraph>
        <Paragraph>
          <div className="bosparagraph">
            <div>2.2.</div>{" "}
            <div>
              Salinan Cadangan: Kami mungkin menyimpan salinan cadangan data
              Anda untuk jangka waktu terbatas sesuai dengan kebijakan retensi
              data kami. Cadangan ini disimpan dengan aman dan akan dihapus pada
              waktunya.
            </div>
          </div>
        </Paragraph>
        <Paragraph>
          <div className="bosparagraph">
            <div>2.3.</div>{" "}
            <div>
              Layanan Pihak Ketiga: Jika data Anda telah dibagikan dengan
              layanan pihak ketiga melalui platform kami, kami akan berupaya
              secara komersial untuk memfasilitasi penghapusan data Anda dari
              layanan tersebut atas permintaan.
            </div>
          </div>
        </Paragraph>
        <Title level={4} className="mtop-40">
          3. Penghapusan Akun
        </Title>
        <Paragraph>
          <div className="bosparagraph">
            <div>3.1.</div>{" "}
            <div>
              Permintaan Penghapusan Akun: Anda dapat meminta penghapusan akun
              BOS Anda kapan saja dengan menghubungi tim dukungan kami atau
              melalui pengaturan akun dalam aplikasi.
            </div>
          </div>
        </Paragraph>
        <Paragraph>
          <div className="bosparagraph">
            <div>3.2.</div>{" "}
            <div>
              <p>
                Konsekuensi Penghapusan Akun: Setelah penghapusan akun Anda:
              </p>
              <p>
                Akses Anda ke platform BOS akan dihentikan. Semua data pribadi
                yang terkait dengan akun Anda akan dihapus sesuai dengan
                Kebijakan Penghapusan Data kami.
              </p>
              <p>
                Anda akan kehilangan akses ke konten, langganan, atau manfaat
                apa pun yang terkait dengan akun Anda.
              </p>
            </div>
          </div>
        </Paragraph>
        <Paragraph>
          <div className="bosparagraph">
            <div>3.3.</div>{" "}
            <div>
              Akun Tidak Aktif: Kami berhak untuk menonaktifkan dan menghapus
              akun yang tidak aktif untuk jangka waktu tertentu, sesuai dengan
              Ketentuan Layanan kami.
            </div>
          </div>
        </Paragraph>
        <Title level={4} className="mtop-40">
          4. Informasi Kontak
        </Title>
        <Paragraph>
          Jika Anda memiliki pertanyaan atau kekhawatiran mengenai Kebijakan
          Penghapusan Data dan Penghapusan Akun kami, silakan hubungi kami di
          [admin@wis-pay.com].
        </Paragraph>
        <Title level={4} className="mtop-40">
          5. Perubahan pada Kebijakan Ini
        </Title>
        <Paragraph>
          Kami berhak untuk memperbarui atau mengubah kebijakan ini kapan saja.
          Setiap perubahan akan disampaikan kepada Anda melalui aplikasi BOS
          atau melalui email.
        </Paragraph>
      </Col>
    </Row>
  )
}

export default BosDataDelete
